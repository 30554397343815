
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent } from 'rxjs';


@Component({
    selector: 'input-debounceComponent',
    template: `
    <div class="input-group" >
            <input type="text"
                   id="inputDebounce"
                   class="form-control  "
                   width="100%"[placeholder]="placeholder"
                   [(ngModel)]="inputValue">
                   </div>
                   `
})
export class InputDebounceComponent {
    @Input() placeholder: string;
    @Input() delay = 500;
    @Output() value: EventEmitter<any> = new EventEmitter();

    public inputValue: string;

    constructor(private elementRef: ElementRef) {
        const eventStream = fromEvent(elementRef.nativeElement, 'keyup')
            .map(() => this.inputValue);
        eventStream.subscribe(input => this.value.emit(input));
    }
}
