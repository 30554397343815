import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Emailblacklist } from 'src/app/models/emailblacklist.model';
import { UnsubscribeService } from 'src/app/services/public/unsubscribe/unsubscribe.service';


@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit {

  emailBlack: Emailblacklist = new Emailblacklist();
  idCorreoEmpresa: string;
  idContacto: string;

  public selectMotiveAux: any[] = [
    { motivo: 'Ya no deseo recibir estos correos' },
    { motivo: 'El contenido de los correos no es de mi interés' },
    { motivo: 'otros' }
  ];

  public selectMotivo: string = this.selectMotiveAux[0].motivo;

  constructor(public router: Router,
              public unsubscribeService: UnsubscribeService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {

      let idCorreoEmpresa = params['idCorreoEmpresa'];
      this.idCorreoEmpresa = idCorreoEmpresa;

      let idContacto = params['idContacto'];
      this.idContacto = idContacto;
    });

  }

  cambioMotivo(EVENTO) {
    console.log('cambio motivo');
    this.selectMotivo = EVENTO;
    this.emailBlack.motivoDesuscripcion = this.selectMotivo;
    console.log(this.emailBlack.motivoDesuscripcion);

  }

  sendUnsubscribe(unsubscribe: Emailblacklist) {

    unsubscribe.idCorreoEmpresa = this.idCorreoEmpresa;
    unsubscribe.idContacto = this.idContacto;
    unsubscribe.motivoDesuscripcion = this.selectMotivo

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });
    Swal.showLoading();
    this.unsubscribeService.sendUnsubscribe(unsubscribe).subscribe(
      _res => {
        Swal.close();
        Swal.fire(
          'Suscripción Cancelada',
          `Ha cancelado la suscripción con éxito.`,
          'success'
        )
          // .then(() => {
          //    window.location.reload();
          // });

      },
      err => {
        if (!err.error) {
        return;
        }
        console.error('Código del error desde el backend: ' + err.status);
      }
    );

  }
}
// }
