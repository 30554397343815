
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { EstadosInstancia } from 'src/app/models/estadosInstancia.model';
import { EmpresaService } from '../../../../services/service.index';

@Component({
  selector: 'estadosInstancia-table',
  templateUrl: './estadosInstancia.component.html',
  styles: []
})
export class EstadosInstanciaComponent implements OnInit {
  estadosInstancia: EstadosInstancia[];

  // paginacion ngb-pagination
  cargando: boolean = false;
  pageObject: any = 0;
  totalElementos: number = 0;
  busqueda: string = '';
  pageSize: number = 0;
  page: number = 0;
  constructor(private authService: AuthService, private _empresaService: EmpresaService) { }

  ngOnInit() {
    this.getEstadosInstancia(this.page);
  }

  getEstadosInstancia(page) {
    this._empresaService.getEstadosInstancia(this.authService.user.idEmpresa, page, this.pageSize).subscribe((response: any) => {
      this.estadosInstancia = response.content as EstadosInstancia[];
      console.log(this.estadosInstancia);
      this.pageObject = response;
      console.log(`pageObject:`);
      console.log(this.pageObject);
      this.pageSize = this.pageObject.size;
      this.totalElementos = response.totalElements;
      if (this.pageObject.empty === true) {
        this.cargando = true;
        this.estadosInstancia = null;
      } else {
        this.cargando = false;
      }
    });
  }


  loadPage(page) {
    console.log('loadPage');
    if (page !== this.pageObject && this.pageObject !== null) {
      this.pageObject = page - 1;
      this.getEstadosInstancia(this.pageObject);
    }
  }

}
