import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, public router: Router) { }

  canActivate() {
    if (this.authService.isAuthenticated()) {
      console.log('authGuard passed');
      return true;
    } else {
      console.log('refused by authGuard');
      // this.router.navigate(['/login']);
      this.router.navigate(['/auth/signin']);
      return false;
    }

  }
}
