<div class="card">
  <div class="card-header bg-c-blue">
    <h5 class="text-white">
      Estados de conexión de números chat
    </h5>
  </div>
  <br>
  <div class="card-body">
    <br>
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-sm" *ngIf="estadosInstancia?.length > 0">
        <thead>
          <tr>
            <th>Instancia</th>
            <th>Número</th>
            <th>Estado Actual</th>
            <th>Estado Anterior</th>
            <th>Fecha cambio estado</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let e of estadosInstancia">
            <td>{{ e.instancia }}</td>
            <td>{{e.numeroEmpresa}}</td>
            <td>{{ e.estado }}</td>
            <td>{{ e.estado_anterior }}</td>
            <td>{{e.fechaCambioEstado}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination #pagination class="d-flex justify-content-center" [collectionSize]="totalElementos"
      [pageSize]="pageSize" [(page)]="page" [maxSize]="5" [boundaryLinks]="true" (pageChange)="loadPage($event)">
      <ng-template ngbPaginationFirst>Primera</ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationLast>Ultima</ng-template>
      <!-- <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template> -->
    </ngb-pagination>
  </div>
</div>
