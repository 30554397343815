import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { AuthService } from 'src/app/auth/auth.service';
import { MensajeService } from 'src/app/services/service.index';
import { EstadosMensajes } from '../../../../models/estadosmensajes.model';



@Component({
  selector: 'chartTotalMensajes',
  templateUrl: './chartTotalMensajes.component.html'
})
export class ChartTotalMensajes implements OnInit, OnChanges {
  // @Input() fechaDesde: string;
  // @Input() fechaHasta: string;
  @Input() numeroEmpresa: string;
  public cargado: boolean = false;
  public estadosMensajes: EstadosMensajes[] = [];
  public totalMensajes: number = 0;
  public totalEnviados: number = 0;
  public totalNoEnviados: number = 0;
  // Doughnut
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: MultiDataSet = [];
  public doughnutChartPlugins = [];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLegend = true;
  public doughnutChartOptions: ChartOptions = {
    responsive: true
  };
  public backgroundColor: any[] = [];
  public doughnutColors = [
  ];




  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private _mensajeService: MensajeService
  ) { }

  ngOnInit() {
    this.numeroEmpresa = "all";
    this.getEstados(this.authService.user.idEmpresa, this.numeroEmpresa);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges');
    // console.log(this.fechaDesde);
    // console.log(this.fechaHasta);
    console.log("numeroEmpresa:" + this.numeroEmpresa);
    if(this.numeroEmpresa){
      this.getEstados(this.authService.user.idEmpresa, this.numeroEmpresa);
    }
  }

  getEstados(idEmpresa: string, remitente: string) {
    this._mensajeService
      .getEstadosMensajesTotal(idEmpresa, remitente)
      .subscribe((response: EstadosMensajes[]) => {
        this.estadosMensajes = response;
        this.estadosMensajes.forEach(estadoMsj => {
          if (estadoMsj.estado == 'PENDIENTE') {
            this.totalNoEnviados += estadoMsj.cantidad;
          } else if (estadoMsj.estado == 'LEIDO') {
            this.totalEnviados += estadoMsj.cantidad
          } else if (estadoMsj.estado == 'ENTREGADO') {
            this.totalEnviados += estadoMsj.cantidad
          } else if (estadoMsj.estado == 'ENVIADO') {
            this.totalEnviados += estadoMsj.cantidad
          } else if (estadoMsj.estado == 'RECIBIDO') {
            this.totalEnviados += estadoMsj.cantidad
          } else if (estadoMsj.estado == 'TEMP') {
            this.totalNoEnviados += estadoMsj.cantidad;
          } else if (estadoMsj.estado == 'ERROR') {
            this.totalNoEnviados += estadoMsj.cantidad;
          } else if (estadoMsj.estado == 'FREEZED') {
            this.totalNoEnviados += estadoMsj.cantidad;
          }

        });
        this.cargarChart();
      });
  }

  cargarChart() {
    //seteamos de vuelta los campos del grafico
    this.totalMensajes = 0
    this.doughnutChartLabels = [];
    this.doughnutChartData  = [];
    let arrayLabels: Label[] = [];
    let array: number[] = [];
    this.estadosMensajes.forEach(mensajeEstado => {
      if (mensajeEstado.estado == 'PENDIENTE') {
        this.backgroundColor.push('#f5b473');
      } else if (mensajeEstado.estado == 'LEIDO') {
        this.backgroundColor.push('#aed0f5');
      } else if (mensajeEstado.estado == 'ENTREGADO') {
        this.backgroundColor.push('#a1ffa6');
      } else if (mensajeEstado.estado == 'ENVIADO') {
        this.backgroundColor.push('#fff6a1');
      } else if (mensajeEstado.estado == 'RECIBIDO') {
        this.backgroundColor.push('#d9fff5');
      } else if (mensajeEstado.estado == 'TEMP') {
        this.backgroundColor.push('#b0b7b5')
      } else if (mensajeEstado.estado == 'ERROR') {
        this.backgroundColor.push('#f59898')
      } else if (mensajeEstado.estado == 'FREEZED') {
        this.backgroundColor.push('#635c5c')
      }
      arrayLabels.push(mensajeEstado.estado);
      array.push(mensajeEstado.cantidad);
      this.totalMensajes += mensajeEstado.cantidad;
    });
    this.doughnutChartLabels = arrayLabels;
    this.doughnutColors.push({ backgroundColor: this.backgroundColor });
    console.log(this.doughnutChartLabels);
    console.log(this.doughnutColors);
    this.doughnutChartData.push(array);
    this.cargado = true;
  }



}

