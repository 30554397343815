import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import { Contacto } from 'src/app/models/contacto.model';
import Swal from 'sweetalert2';


@Injectable()
export class ContactoService {
  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  getAll(id: string) {
    const url = BASE_URL + 'contactos?idEmpresa=' + id;
    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      });
  }

  loadContactos(idEmpresa: string) {
    const url = `${BASE_URL}contactos?idEmpresa=${idEmpresa}&size=5`;
    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      });
  }

  getContactos(idEmpresa: string, termino: string, page: number, size: number) {
    let url;
    if (termino === '') {
      url = `${BASE_URL}contactos?idEmpresa=${idEmpresa}&size=${size}&page=${page}`;
    } else {
      url = `${BASE_URL}contactos?idEmpresa=${idEmpresa}&termino=${termino}&size=${size}&page=${page}`;
    }
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => response);
  }

  findContactosByTerm(idEmpresa: string, termino: string) {
    const url = `${BASE_URL}contactos?idEmpresa=${idEmpresa}&page=0&size=0&termino=${termino}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        return response.content;
      });
  }

  delete(id: string): Observable<Contacto> {
    return this.http
      .delete<Contacto>(`${BASE_URL}contactos/${id}`, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  getContactosById(id) {
    const url = `${BASE_URL}contactos/${id}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  create(contacto: Contacto): Observable<Contacto> {
    return this.http
      .post(`${BASE_URL}contactos`, contacto, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  createAll(contactos: Contacto[]): Observable<any> {
    return this.http
      .post(`${BASE_URL}contactos/import`, contactos, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(contacto: Contacto): Observable<Contacto> {
    return this.http
      .put<any>(`${BASE_URL}contactos`, contacto, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }
}
