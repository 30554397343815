<div class="container"
  *ngIf="this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header" style="justify-content: center;background: #3b4650;">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <a [routerLink]="['/dashboard']" class="b-brand">
      <!-- <img id="main-logo" src="/assets/images/logo.png" alt="" class="logo"> -->
      <img id="main-logo" src="/assets/images/mchat-dark.png" alt="" style="  max-height: 60px;
      height: auto;
      max-width: 45px;
      width: auto;
      position: relative;">
      <!-- <img src="/assets/images/logo-icon.png" alt="" class="logo-thumb"> -->
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i
        class="feather icon-more-vertical"></i>
    </a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-left class="mr-auto"></app-nav-left>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>
