import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { tap, map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Respuesta } from '../../models/respuesta.model';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import { Sucursal } from 'src/app/models/sucursal.model';


@Injectable()
export class SucursalService {

  private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' });

  headers: HttpHeaders;

  constructor(
              public http: HttpClient,
              public router: Router,
              public authService: AuthService
             ) {
              this.headers = new HttpHeaders({
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + this.authService.token
              });
            }

  getSucursales(idEmpresa: string) {
    let url = BASE_URL + 'sucursales?idEmpresa=' + idEmpresa;
    console.log(url);
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  getById(id: number) {
    let url = BASE_URL + 'sucursales/' + id;
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }

  create(suc: Sucursal): Observable<Sucursal> {
    return this.http.post(BASE_URL + 'sucursales', suc, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(suc: Sucursal): Observable<Sucursal> {
    return this.http.put<any>(BASE_URL + 'sucursales?idEmpresa=', suc, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  delete(id: string): Observable<Sucursal> {
    return this.http
      .delete<Sucursal>(BASE_URL + 'sucursales' + `/${id}`, {
        headers: this.headers
      })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

}
