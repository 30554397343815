import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Contacto } from 'src/app/models/contacto.model';
import { ContactoService } from 'src/app/services/service.index';
import { AuthService } from 'src/app/auth/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-ngselect-contacto',
  templateUrl: './ng-select-contacto.component.html',
  providers: [ContactoService],
  styles: [``]
})
export class NGSelectContactoComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() cargadorContactos: Contacto;
  @Output('retornoObjeto') retornoObjeto: EventEmitter<Contacto> = new EventEmitter();
  searching = false;
  searchFailed = false;
  cargando = false;
  contactos: Contacto[] = [];
  model: any;
  // nombre: string;

  ngOnInit() {
    this.cargarContactos();
  }

  constructor(
    public contactoServices: ContactoService,
    public authService: AuthService
  ) { }

  cargarContactos() {
    this.cargando = true;
    this.contactoServices
      .loadContactos(this.authService.user.idEmpresa)
      .subscribe(resp => {
        this.contactos = resp.content as Contacto[];
        this.cargando = false;
        console.log(this.contactos);
      });
  }

  onSearch(event) {
    const term = event.term;
    // debounceTime(300);
    distinctUntilChanged();
    if (term.length <= 1) {
      this.ngOnInit();
      return;
    }
    console.log(term);
    this.contactoServices
      .findContactosByTerm(this.authService.user.idEmpresa, term)
      .subscribe(contactos => {
        this.contactos = contactos as Contacto[];
        console.log(this.contactos);
      });
  }

  selectedItem(item) {
    this.cargadorContactos = item;
    this.ngSelectComponent.handleClearClick();
  }

  agregar() {
    this.retornoObjeto.emit(this.cargadorContactos);
    this.ngSelectComponent.handleClearClick();
  }
}
