import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import Swal from 'sweetalert2';
import { Contacto } from '../../models/contacto.model';
import { Lista } from '../../models/lista.model';

@Injectable()
export class ListaDifusionService {
  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': 'Bearer ' + this.authService.token
    });
  }

  getListasDifusion(idEmpresa: string, termino: string, page: number, size: number) {
    let url = '';
    if (termino === '') {
      url = `${BASE_URL}listadifusion?idEmpresa=${idEmpresa}&size=${size}&page=${page}`;
    } else {
      url = `${BASE_URL}listadifusion?idEmpresa=${idEmpresa}&termino=${termino}&size=${size}&page=${page}`;
    }
    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      });
  }

  findListasByTerm(idEmpresa: string, termino: string) {
    const url = `${BASE_URL}listadifusion?idEmpresa=${idEmpresa}&page=0&termino=${termino}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        return response.content;
      });
  }

  delete(id: string): Observable<Lista> {
    return this.http
      .delete<Lista>(`${BASE_URL}listadifusion/${id}`, {
        headers: this.headers
      })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  getListaById(id) {
    const url = `${BASE_URL}listadifusion/${id}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  getContactosLista(idLista: string, page: number, size: number) {
    let url;
    url = `${BASE_URL}listadifusion/contactos?idLista=${idLista}&page=${page}&size=${size}`;
    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      });
  }

  findContactosLista(idLista: string,  termino: string, page: number, size: number) {
    let url;
    if (termino === '') {
      url = `${BASE_URL}listadifusion/contactos?idLista=${idLista}&page=${page}&size=${size}`;
    } else {
      url = `${BASE_URL}listadifusion/contactos?idLista=${idLista}&termino=${termino}&page=${page}&size=${size}`;
    }
    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      });
  }

  create(lista: Lista): Observable<Lista> {
    return this.http
      .post(`${BASE_URL}listadifusion`, lista, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error.message);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  createContactosLista(idLista: string, contactos: Contacto[]): any {
    const url = `${BASE_URL}listadifusion/contactos/${idLista}`;
    return this.http
      .post(url, contactos, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error.message);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(list: Lista): Observable<Lista> {
    return this.http
      .put<any>(`${BASE_URL}listadifusion`, list, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  deleteContactoLista(id: string) {
    const url = `${BASE_URL}listadifusion/deleteContacto/${id}`;
    return this.http.delete(url)
      .map((response: any) => {
        return response;
      });
  }

  addContacto(contacto: Contacto, idLista: string): Observable<Lista> {
    const url = `${BASE_URL}listadifusion/addContacto/${idLista}`;
    return this.http
      .post<any>(url, contacto, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.mensaje, 'error');
          return throwError(e);
        })
      );
  }

}
