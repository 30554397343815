import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    // styleUrls: ['/error-page-component.scss']
})

export class ErrorPageComponent {
  constructor(public router: Router){

  }
  goBack(){
    // this.router.navigate(['/dashboard/default'])
    this.router.navigate(['/auth/signin']);
  }

}
