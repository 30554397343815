import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { BASE_URL } from '../../config/config';
import { tap, map, catchError } from 'rxjs/operators';
import { _throw as throwError } from 'rxjs/observable/throw';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';


@Injectable()
export class DashboardService {

  headers: HttpHeaders; // new HttpHeaders();

  constructor(
                public http: HttpClient,
                public router: Router,
                public authService: AuthService
              ) {
                this.headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' +  this.authService.token
    });
  }

  /*  traerByCodEmp(codeEmpresa) {
   let url = BASE_URL + 'bancos?codempresa=' + codeEmpresa;
     console.log( url, { headers: this.headers});
     return this.http.get( url, { headers: this.headers})
     .map( (resp: any) => resp);
    }


    getTopProductos( fechaInicio , fechaFin, codUsuario, codsucursal)  {
      let url: string;
      if (codUsuario == 0 && codsucursal == 0) {
        url  = BASE_URL + 'ventas/topproductos?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin;
      } else  if (codUsuario != 0 && codsucursal == 0) {
        url  = BASE_URL + 'ventas/topproductos?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario;
      } else  if (codUsuario == 0 && codsucursal != 0) {
        url  = BASE_URL + 'ventas/topproductos?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        +  '&codsucursal=' + codsucursal;
      } else  if (codUsuario != 0 && codsucursal != 0) {
        url  = BASE_URL + 'ventas/topproductos?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario +  '&codsucursal=' + codsucursal;
      }
       return this.http.get(url, { headers: this.headers }).pipe(
        catchError(e => {
          console.error('ERROR', e.error);
            Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
    }

    getResumenMediopago(fechaInicio , fechaFin, codUsuario, codsucursal)  {
      let url: string;
      if (codUsuario == 0 && codsucursal == 0) {
        url  = BASE_URL + 'cobranzas/resumenmediopago?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin;
      } else  if (codUsuario != 0 && codsucursal == 0) {
        url  = BASE_URL + 'cobranzas/resumenmediopago?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario;
      } else  if (codUsuario == 0 && codsucursal != 0) {
        url  = BASE_URL + 'cobranzas/resumenmediopago?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        +  '&codsucursal=' + codsucursal;
      } else  if (codUsuario != 0 && codsucursal != 0) {
        url  = BASE_URL + 'cobranzas/resumenmediopago?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario +  '&codsucursal=' + codsucursal;
      }

      return this.http.get(url, { headers: this.headers }).pipe(
        catchError(e => {
          console.error('ERROR', e.error);
            Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
    }

    getTopClientes(fechaInicio , fechaFin, codUsuario, codsucursal)  {
      let url: string;
      if (codUsuario == 0 && codsucursal == 0) {
        url  = BASE_URL + 'ventas/topclientes?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin;
      } else  if (codUsuario != 0 && codsucursal == 0) {
        url  = BASE_URL + 'ventas/topclientes?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario;
      } else  if (codUsuario == 0 && codsucursal != 0) {
        url  = BASE_URL + 'ventas/topclientes?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        +  '&codsucursal=' + codsucursal;
      } else  if (codUsuario != 0 && codsucursal != 0) {
        url  = BASE_URL + 'ventas/topclientes?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario +  '&codsucursal=' + codsucursal;
      }
     return this.http.get(url, { headers: this.headers }).pipe(
        catchError(e => {
          console.error('ERROR', e.error);
            Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
    }

    getResumenUsuario(fechaInicio , fechaFin, codUsuario, codsucursal)  {
      let url: string;
      if (codUsuario == 0 && codsucursal == 0) {
        url  = BASE_URL + 'ventas/resumenusuario?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin;
      } else  if (codUsuario != 0 && codsucursal == 0) {
        url  = BASE_URL + 'ventas/resumenusuario?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario;
      } else  if (codUsuario == 0 && codsucursal != 0) {
        url  = BASE_URL + 'ventas/resumenusuario?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        +  '&codsucursal=' + codsucursal;
      } else  if (codUsuario != 0 && codsucursal != 0) {
        url  = BASE_URL + 'ventas/resumenusuario?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        + '&codusuario=' + codUsuario +  '&codsucursal=' + codsucursal;
      }
      return this.http.get(url, { headers: this.headers }).pipe(
        catchError(e => {
          console.error('ERROR', e.error);
            Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
    }

    getResumenSucursal(fechaInicio , fechaFin,   codsucursal)  {
      let url: string;
      if ( codsucursal == 0) {
        url  = BASE_URL + 'ventas/resumensucursal?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin;
      }  else  if ( codsucursal != 0) {
        url  = BASE_URL + 'ventas/resumensucursal?fechainicio=' + fechaInicio + '&fechafin=' + fechaFin
        +  '&codsucursal=' + codsucursal;
      }
     return this.http.get(url, { headers: this.headers }).pipe(
        catchError(e => {
          console.error('ERROR', e.error);
            Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
    } */



}
