import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { ErrorPageComponent } from './theme/layout/auth/pages/error/error-page.component';
import { AuthTokenGuard } from './auth/auth-token.guard';
import { UnsubscribeComponent } from './public/unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        canActivate: [AuthTokenGuard],
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./dashboard/dash.module').then(module => module.DashModule)
      },
      {
        path: 'campanas',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./campanas/campanas.module').then(m => m.CampanasModule)
      },
      {
        path: 'conversaciones',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./conversaciones/conversaciones.module').then(m => m.ConversacionesModule)
      },
      {
        path: 'chatbot',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./chatbot/chatbot.module').then(m => m.ChatbotModule)
      },
      {
        path: 'configuracion',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./theme/layout/auth/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },
  { path: 'mail-unsubscribe', component: UnsubscribeComponent },
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
