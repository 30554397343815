import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import { CorreoEmpresa } from 'src/app/models/correoEmpresa.model';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import Swal from 'sweetalert2';


@Injectable()
export class CorreoEmpresaService {

  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + this.authService.token
    });
  }

  getCorreosEmpresa(idEmpresa: string) {
    let url = BASE_URL + 'correosEmpresa' + '?idEmpresa=' + idEmpresa;
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  create(correoEmp: CorreoEmpresa): Observable<CorreoEmpresa> {
    return this.http.post(BASE_URL + 'correosEmpresa', correoEmp, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(correoEmp: CorreoEmpresa): Observable<CorreoEmpresa> {
    return this.http.put<any>(BASE_URL + 'correosEmpresa', correoEmp, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  delete(id: string): Observable<CorreoEmpresa> {
    return this.http
      .delete<CorreoEmpresa>(BASE_URL + 'correosEmpresa' + `/${id}`, {
        headers: this.headers
      })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

}
