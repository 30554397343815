import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscribeRoutingModule } from './unsubscribe-routing.module';
import { UnsubscribeComponent } from './unsubscribe.component';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { ServiceModule } from 'src/app/services/service.module';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    UnsubscribeComponent
  ],
  imports: [
    CommonModule,
    UnsubscribeRoutingModule,
    ServiceModule,
    HttpClientModule,
    SharedModule
  ]
})
export class UnsubscribeModule { }
