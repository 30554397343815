import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  // para que sea posible aplicar el guard en cualquier ruta
  providedIn: 'root'
})
export class AuthTokenGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  canActivate(): Promise<boolean> | boolean {
    console.log('AuthToken Guard');
    let token = this.authService.token;
    let payload = JSON.parse(atob(token.split('.')[1]));

    let ex = new Date(payload.exp * 1000);
    console.log('token exp', ex);
    let expirado = this.expirado(payload.exp);
    if (expirado) {
      this.router.navigate(['/auth/signin']);
      return false;
    }
    return this.verificaRenueva(payload.exp);
  }

  verificaRenueva(fechaExp: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let tokenExp = new Date(fechaExp * 1000);
      let ahora = new Date();
      // ahora.setTime(ahora.getTime() + 10 * 60 * 60 * 1000);
      ahora.setTime(ahora.getTime() + 7 * 60 * 1000);

      let temp = new Date();
      console.log('limit', tokenExp);
      console.log(' ahora', ahora);

      if (tokenExp.getTime() > ahora.getTime()) {
        resolve(true);
      } else {
        this.authService.renewToken().subscribe(() => {
          resolve(true);
        }, () => {
          this.router.navigate(['/auth/signin']);
          reject(false);
        }
        );
      }
    });
  }

  expirado(fechaExp: number) {
    let ahora = new Date().getTime() / 1000;
    if (fechaExp < ahora) {
      return true;
    } else {
      return false;
    }

  }
}
