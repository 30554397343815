import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../config/config';
import { Campana } from '../../models/campana.model';
import { Contacto } from '../../models/contacto.model';
import { Lista } from '../../models/lista.model';

@Injectable()
export class CampanaService {
  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  getCampanas(idEmpresa: string, activo: boolean, tipoCampana: string, pageSize: number, page: number) {
    if (activo != null && tipoCampana == null) {
      const url = `${BASE_URL}campanas?idEmpresa=${idEmpresa}&activo=${activo}&size=${pageSize}&page=${page}`;
      return this.http
        .get(url, { headers: this.headers })
        .map((response: Campana[]) => {
          return response;
        });
    } else if (activo == null && tipoCampana != null) {
      const url = `${BASE_URL}campanas?idEmpresa=${idEmpresa}&tipoCampana=${tipoCampana}&size=${pageSize}&page=${page}`;
      return this.http
        .get(url, { headers: this.headers })
        .map((response: Campana[]) => {
          return response;
        });
    } else if (activo != null && tipoCampana != null) {
      const url = `${BASE_URL}campanas?idEmpresa=${idEmpresa}&activo=${activo}&tipoCampana=${tipoCampana}&size=${pageSize}&page=${page}`;
      return this.http
        .get(url, { headers: this.headers })
        .map((response: Campana[]) => {
          return response;
        });
    } else {
      const url = `${BASE_URL}campanas?idEmpresa=${idEmpresa}&size=${pageSize}&page=${page}`;
      return this.http
        .get(url, { headers: this.headers })
        .map((response: any) => {
          return response;
        });
    }
  }

  getById(id: string) {
    return this.http
      .get<Campana>(`${BASE_URL}campanas${`/${id}`}`, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  getListasCampana(id: string) {
    const url = `${BASE_URL}campanas/listas/${id}`;
    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      });
  }

  getContactosCampana(id: string, termino: string, page: number, size: number) {
    let url: string;
    if (termino === '' || termino === undefined) {
      url = `${BASE_URL}campanas/contactos?idCamp=${id}&page=${page}&size=${size}`;
    } else {
      url = `${BASE_URL}campanas/contactos?idCamp=${id}&termino=${termino}&page=${page}&size=${size}`;
    }
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  create(campana: Campana): Observable<Campana> {
    return this.http
      .post<any>(`${BASE_URL}campanas`, campana, { headers: this.headers })
      .pipe(
        catchError(e => {
          if (e.status === 400) {
            return throwError(e);
          }
          console.error(e.error.mensaje);
          Swal.fire(e.error.mensaje, e.error.error, 'error');
          return throwError(e);
        })
      );
  }

  createContactosCampana(idCamp: string, contactos: Contacto[]) {
    const url = `${BASE_URL}campanas/contactos/${idCamp}`;
    return this.http
      .post(url, contactos, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error.message);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(campana: Campana): Observable<Campana> {
    return this.http
      .put<any>(`${BASE_URL}campanas`, campana, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  updateEstado(campana: Campana): Observable<Campana> {
    return this.http
      .put<any>(`${BASE_URL}campanas/estado`, campana, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  delete(id: string): Observable<Campana> {
    return this.http
      .delete<Campana>(`${BASE_URL}campanas${`/${id}`}`, {
        headers: this.headers
      })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  addContacto(contacto: Contacto, idCamp: string) {
    const url = `${BASE_URL}campanas/addContacto/${idCamp}`;
    return this.http
      .put<any>(url, contacto, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.mensaje, 'error');
          return throwError(e);
        })
      );
  }

  addLista(lista: Lista, idCamp: string) {
    const url = `${BASE_URL}campanas/addLista/${idCamp}`;
    return this.http
      .put<any>(url, lista, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.mensaje, 'error');
          return throwError(e);
        })
      );
  }

  deleteContacto(id: string) {
    const url = `${BASE_URL}campanas/deleteContacto/${id}`;
    return this.http.delete(url)
      .map((response: any) => {
        return response;
      });
  }

  deleteLista(lista: Lista, idCamp: string) {
    const url = `${BASE_URL}campanas/deleteLista/${idCamp}`;
    return this.http.put(url, lista)
      .map((response: any) => {
        return response;
      });
  }

  send(id) {
    return this.http
      .post<any>(`${BASE_URL}campanas/send/${id}`, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  uploadImage(img, idCampana): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', img);
    formData.append('id', idCampana);
    const url = `${BASE_URL}campanas/upload-image`;
    return this.http.post(url, formData).map((response: any) => {
      return response;
    });
  }

  getImagen(imgName: string) {
    const url = `${BASE_URL}campanas/download-image/${imgName}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
