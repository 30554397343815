<!-- <div class="form-group "> -->
<!-- <div class="row"> -->
<ng-select [items]="listasDifusion" bindLabel="nombre" [loading]="cargando" placeholder="Ingrese lista ..."
  [(ngModel)]="cargadorListas" (search)="onSearch($event)">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <b [ngOptionHighlight]="search">{{item.nombre}}</b>
  </ng-template>
</ng-select>
<button class="btn btn-outline-secondary" type="button" (click)="agregar()">
  <i class="fa fa-plus-square"></i>
  <span>
    Agregar</span>
</button>
<!-- </div> -->
