import { AuthService } from 'src/app/auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASE_URL } from 'src/app/config/config';
import { Mensaje } from 'src/app/models/mensaje.model';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { throwError } from 'rxjs';


@Injectable()
export class MensajeService {
  private headers: HttpHeaders;
  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  getEstadosMensajes(idCampana: string) {
    const url = `${BASE_URL}mensajes/status?idCampana=${idCampana}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        console.log(response);
        return response;
      });
  }

  getEstadosMensajesTotal(idEmpresa: string, remitente: string) {
    console.log('remitente desde mensj. service: ' + remitente);
    let url;
    if (remitente === 'all') {
      url = `${BASE_URL}mensajes/totalstatus?idEmpresa=${idEmpresa}`;
    } else {
      url = `${BASE_URL}mensajes/totalstatusByRem?idEmpresa=${idEmpresa}&remitente=${remitente}`;
    }
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => {
        console.log(response);
        return response;
      });
  }

  getTotalMensajesCampana(idCampana: string) {
    const url = `${BASE_URL}mensajes/totalCampana?idCampana=${idCampana}`;
    return this.http.get(url, { headers: this.headers }).map((response: any) => response);
  }

  getMensajesPreview(idEmpresa: string, remitente: string, destinatario: string, page: number, size: number) {
    let url = '';
    url = `${BASE_URL}mensajes/groupbycontactos?idEmpresa=${idEmpresa}&remitente=${remitente}&destinatario=${destinatario}
    &page=${page}&size=${size}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => response);
  }

  getMensajesCampana(idCampana: string, estado: string, termino: string, page: number, size: number) {
    let url = '';
    if (estado === '' && termino === '') {
      url = `${BASE_URL}mensajes/campana?idCampana=${idCampana}&page=${page}&size=${size}`;
    } else if (estado !== '' && termino === '') {
      url = `${BASE_URL}mensajes/campana?idCampana=${idCampana}&estado=${estado}&page=${page}&size=${size}`;
    } else if (estado === '' && termino !== '') {
      url = `${BASE_URL}mensajes/campana?idCampana=${idCampana}&termino=${termino}&page=${page}&size=${size}`;
    } else if (estado !== '' && termino !== '') {
      url = `${BASE_URL}mensajes/campana?idCampana=${idCampana}&estado=${estado}&termino=${termino}&page=${page}&size=${size}`;
    }
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => response);
  }

  getConversacion(idEmpresa: string, remitente: string, destinatario: string) {
    const url = `${BASE_URL}mensajes?idEmpresa=${idEmpresa}&remitente=${remitente}&destinatario=${destinatario}`;
    return this.http
      .get(url, { headers: this.headers })
      .map((response: any) => response);
  }

  sendWebMessage(mensaje: Mensaje) {
    const url = `${BASE_URL}mensajes/webmessage`;
    return this.http
      .post(url, mensaje, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error.message);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

}
