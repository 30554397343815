import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url:'/dashboard',
        icon: 'fa fa-tachometer-alt',
        breadcrumbs: false
      },
      {
        id: 'campanas',
        title: 'Campañas',
        type: 'item',
        url: '/campanas',
        icon: 'fa fa-paper-plane',
        classes: 'nav-item',
      },
      {
        id: 'conversaciones',
        title: 'Conversaciones',
        type: 'item',
        url: '/conversaciones',
        icon: 'fa fa-comments',
        classes: 'nav-item',
      }
    ]
  },
  {
    id: 'ui-element',
    title: 'UI ELEMENT',
    type: 'group',
    icon: 'icon-ui',
    children: [
      {
        id: 'chatbot',
        title: 'Bot',
        type: 'collapse',
        icon: 'fab fa-whatsapp',
        children: [
          {
            id: 'preguntas',
            title: 'Preguntas',
            type: 'item',
            url: '/chatbot/preguntas'
          },
          {
            id: 'respuestas',
            title: 'Respuestas',
            type: 'item',
            url: '/chatbot/respuestas'
          }
        ]
      }
    ]
  },
  {
    id: 'ui-element',
    title: 'UI ELEMENT',
    type: 'group',
    icon: 'icon-ui',
    children: [
      {
        id: 'configuracion',
        title: 'Configuracion',
        type: 'collapse',
        icon: 'fa fa-cog',
        children: [
          {
            id: 'parametros',
            title: 'Parametros',
            type: 'item',
            url: '/chatbot/parametros'
          },
          {
            id: 'contactos',
            title: 'Contactos',
            type: 'item',
            url: '/configuracion/contactos'
          },
          {
            id: 'listas',
            title: 'Listas de Difusion',
            type: 'item',
            url: '/configuracion/listas'
          },
          {
            id: 'usuarios',
            title: 'Usuarios',
            type: 'item',
            url: '/configuracion/usuarios'
          },
          {
            id: 'empresa',
            title: 'Empresa',
            type: 'item',
            url: '/configuracion/empresa'
          }
        ]
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
