import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TokenInterceptor } from '../auth/token.interceptor';
import { CorreoEmpresaService } from './correoEmpresa/correoEmpresa.service';
import {
  CampanaService, ContactoService, DashboardService, DataCopyService, EmpresaService, ExcelService, ListaDifusionService, MensajeService,
  NumeroEmpresaService, ParametroService, PreguntaService, RespuestaService, RolService, SubirArchivoService, SucursalService, UsuarioService
} from './service.index';





@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    CampanaService,
    ContactoService,
    ListaDifusionService,
    ParametroService,
    RespuestaService,
    PreguntaService,
    UsuarioService,
    EmpresaService,
    DashboardService,
    SubirArchivoService,
    MensajeService,
    RolService,
    NumeroEmpresaService,
    SucursalService,
    DataCopyService,
    ExcelService,
    CorreoEmpresaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  declarations: []
})
export class ServiceModule { }
