<ng-select [items]="parametros" bindLabel="clave" [loading]="cargando" placeholder="Ingrese parametro..."
  (selectItem)="selectedItem($event.item)" [(ngModel)]="cargadorParametros">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <b [ngOptionHighlight]="search">{{item.concatClaveValor}}</b>
  </ng-template>
</ng-select>
<button class="btn btn-outline-secondary" type="button" (click)="agregar()">
  <i class="fa fa-plus-square"></i>
  <span>Agregar</span>
</button>

