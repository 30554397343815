<div class="auth-wrapper">
  <div class="container">
    <div class="row justify-content-center"> <img src="/assets/images/unsubscribed.png" alt="unsubscribed" width="50%"
        height="50%">
      <div class="col-md-6 mt-5 mb-5">
        <form class="form-horizontal">
          <div class="form-group mt-5 mb-5">
            <div class="col-sm-12">
              <label for="empresaEmail" class="control-label text-white">Si tiene un momento, por favor
                díganos por qué cancela la suscripción? </label>
                <div class="row form-group mt-5 text-white">
                  <div class="col-md-12">
                    <label for="selectMotivo">Seleccionar motivo:</label>
                    <select id="selectMotivo" class="form-control custom-select" style="width: 100%" [(ngModel)]="selectMotivo"
                      ngbTooltip="Seleccione motivo a filtrar" name="selectMotivo"
                      (ngModelChange)="cambioMotivo($event)">
                      <option style="width: 100%" *ngFor="let c of selectMotiveAux" [ngValue]="c.motivo"
                        [selected]="c === 'Ya no deseo recibir estos correos'">
                        {{ c.motivo }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
          </div>
          <div class="form-group mt-5">
            <div class="col-sm-12">
              <button type="button" (click)="sendUnsubscribe(emailBlack)" class="btn btn-block btn-outline-light">Cancelar Suscripción</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
