import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { tap, map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Respuesta } from '../../models/respuesta.model';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';


@Injectable()
export class RolService {

  private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' });

  headers: HttpHeaders;

  constructor(
              public http: HttpClient,
              public router: Router,
              public authService: AuthService
             ) {
              this.headers = new HttpHeaders({
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + this.authService.token
              });
            }

  getRoles() {
    let url = BASE_URL + 'roles';
    console.log(url);
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

/*
  delete(id: string): Observable<Respuesta> {
    return this.http.delete<Respuesta>(`${BASE_URL}respuestas/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
      );
    }

    getById(id ) {
      let url = BASE_URL + 'respuestas/' + id;
      console.log(url);
      return this.http.get(url, { headers: this.headers })
        .map((response: any) => {
          return response;
        });
    }

  create(res: Respuesta): Observable<Respuesta> {
    return this.http.post(BASE_URL + 'respuestas', res, { headers: this.headers } )
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }
  update(res: Respuesta): Observable<Respuesta> {
    return this.http.put<any>(BASE_URL + 'respuestas', res, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }
 */

}
