import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';
import { Usuario } from '../../models/usuario.model';
import { SubirArchivoService } from '../subir-archivo/subir-archivo.service';

@Injectable()
export class UsuarioService {

  user: User;
  headers: HttpHeaders;
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService,
    public subirArchivoService: SubirArchivoService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  getUsuariospaginados(idEmpresa: string, termino: string, page: number) {
    let url = '';
    if (termino === '') {
      url = `${BASE_URL}usuarios?idEmpresa=${idEmpresa}&page=${page}&size=10`;
    } else {
      url = `${BASE_URL}usuarios?idEmpresa=${idEmpresa}&size=10&page=${page}&keyword=${termino}`;
    }
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => response);
  }

  uploadImage(imagen, code): Observable<any> {
    this.headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token
    });
    const formData: FormData = new FormData();
    formData.append('id', code);
    formData.append('image', imagen);
    const url = BASE_URL + 'usuarios/upload-image';
    return this.http.post(url, formData, { headers: this.headers })
      .map((response: any) => {
        console.log(response);
        return response;
      });
  }

  traerEmpresasPorId(id: number) {
    const url = `${BASE_URL}empresas/${id}`;
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }

  getById(id): Observable<Usuario> {
    return this.http.get<Usuario>(`${BASE_URL}usuarios${`/${id}`}`, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getUsuarioByIdForImg(id): Observable<Usuario> {
    return this.http.get<Usuario>(`${BASE_URL}usuarios${`/${id}`}`, { headers: this.headers }).pipe(
      tap((response: any) => response as Usuario)
    );
  }

  create(usuario: Usuario) {
    return this.http.post<any>(`${BASE_URL}usuarios`, usuario, { headers: this.headers })
      .pipe(
        tap((response: any) => response.usuario as Usuario),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(usuario: Usuario): Observable<any> {
    return this.http.put<any>(`${BASE_URL}usuarios`, usuario, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  delete(id: string): Observable<any> {
    return this.http.delete<Usuario>(`${BASE_URL}usuarios${`/${id}`}`, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

}
