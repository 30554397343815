import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import Swal from 'sweetalert2';
import { Pregunta } from '../../models/pregunta.model';



@Injectable()
export class PreguntaService {

  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + this.authService.token
    });
  }

  get(id: string) {
    const url = `${BASE_URL}preguntas?idEmpresa=${id}`;
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  delete(id: string): Observable<Pregunta> {
    return this.http.delete<Pregunta>(`${BASE_URL}preguntas/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getById(id) {
    const url = `${BASE_URL}preguntas/${id}`;
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  create(pre: Pregunta): Observable<Pregunta> {
    return this.http.post(`${BASE_URL}preguntas`, pre, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(pre: Pregunta): Observable<Pregunta> {
    return this.http.put<any>(`${BASE_URL}preguntas`, pre, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

}
