export class Emailblacklist {
  public id?: string;
  public idEmpresa?: string;
  public correoEmpresa?: string;
  public idCorreoEmpresa: string;
  public idContacto: string;
  public correoContacto?: string;
  public motivoDesuscripcion: string;
  public fechaDesuscripcion?: string;
}
