import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASE_URL } from 'src/app/config/config';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { throwError, Observable } from 'rxjs';
import { Emailblacklist } from 'src/app/models/emailblacklist.model';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  headers: HttpHeaders;
  

  constructor(
    public http: HttpClient,
    public router: Router,
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  sendUnsubscribe(emailBlack: Emailblacklist): Observable<Emailblacklist> {
    return this.http
      .post<any>(BASE_URL + 'mail-unsubscribe' , emailBlack, { headers: this.headers })
      .pipe(
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

}
