<div class="card">
  <div class="card-header bg-c-blue">
    <h5 class="text-white">
      Top Mensajes
    </h5>
  </div>
  <br>
  <div class="card-body">
    <div  *ngIf="cargado">
      <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
        [plugins]="doughnutChartPlugins" [legend]="doughnutChartLegend" [colors]='doughnutColors'>
      </canvas>
    </div>

    <div class="stats-bar" *ngIf="cargado">
      <div  *ngIf="cargado">
        <div class="row mt-4 text-center">
          <div class="col">
            <h5 class="m-0">
              <i class="fas fa-circle f-10 m-r-5 text-primary"></i>
              {{totalMensajes}}
            </h5>
            <span class="ml-3">Total de mensajes</span>
          </div>
          <div class="col">
            <h5 class="m-0"><i class="fas fa-circle text-c-blue f-10 m-r-5 text-success"></i>
            {{totalEnviados}}
            </h5><span
              class="ml-3">Enviados</span>
          </div>
          <div class="col">
            <h5 class="m-0"><i class="fas fa-circle text-c-purple f-10 m-r-5 text-danger"></i>
              {{totalNoEnviados}}
            </h5><span
              class="ml-3">No enviados</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
