import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Lista } from 'src/app/models/lista.model';
import { ListaDifusionService } from 'src/app/services/service.index';

@Component({
  selector: 'app-ngselect-listadifusion',
  templateUrl: './ng-select-listadifusion.component.html',
  providers: [ListaDifusionService],
  styles: [``]
})
export class NGSelectListadifusionComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() cargadorListas: Lista;
  @Output('retornoObjeto') retornoObjeto: EventEmitter<Lista> = new EventEmitter();
  // events: Event[] = [];
  searching = false;
  searchFailed = false;
  cargando = false;
  listasDifusion: Lista[] = [];
  model: any;
  nombre: string;
  pageSize = 5;
  page = 0;
  busqueda = '';

  constructor(
    private authService: AuthService,
    private listaDifusionService: ListaDifusionService
  ) { }

  ngOnInit() {
    this.cargarListas();
  }

  cargarListas() {
    this.cargando = true;
    this.listaDifusionService
      .getListasDifusion(this.authService.user.idEmpresa, this.busqueda, this.page, this.pageSize)
      .subscribe(resp => {
        this.listasDifusion = resp.content as Lista[];
        this.cargando = false;
        console.log(this.listasDifusion);
      });
  }

  onSearch(event) {
    const term = event.term;
    // debounceTime(300);
    distinctUntilChanged();
    if (term.length <= 1) {
      this.ngOnInit();
      return;
    }
    console.log(term);
    this.listaDifusionService
      .findListasByTerm(this.authService.user.idEmpresa, term)
      .subscribe(listas => {
        this.listasDifusion = listas as Lista[];
        console.log(this.listasDifusion);
      });
  }

  /*   onAdd(item) {
      this.cargadorListas = item;
      this.listasCargadas.push(this.cargadorListas);
      console.log(this.listasCargadas);
    } */

  selectedItem(item) {
    this.cargadorListas = item;
  }

  agregar() {
    this.retornoObjeto.emit(this.cargadorListas);
    // console.log(this.listasCargadas);
    // this.listasCargadas = [];
    this.ngSelectComponent.handleClearClick();
  }
}
