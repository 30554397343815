import { Component, OnInit, Output, ViewChild, Input, EventEmitter } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'src/app/auth/auth.service';
import { Parametro } from 'src/app/models/parametro.model';
import { ParametroService } from 'src/app/services/service.index';

@Component({
  selector: 'app-ngselect-parametros',
  templateUrl: './ng-select-parametros.component.html',
  styles: [``]
})
export class NGSelectParametrosComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() cargadorParametros: Parametro;
  @Output('retornoObjeto') retornoObjeto: EventEmitter<Parametro> = new EventEmitter();
  searching = false;
  searchFailed = false;
  cargando = false;
  parametros: Parametro[] = [];
  model: any;
  nombre: string;

  ngOnInit() {
    this.cargarParametros();
  }

  constructor(
    public parametroService: ParametroService,
    public authService: AuthService
  ) { }

  cargarParametros() {
    this.cargando = true;
    this.parametroService
      .getParametros(this.authService.user.idEmpresa)
      .subscribe(response => {
        console.log(response);
        this.parametros = response as Parametro[];
        this.cargando = false;
      });
  }

  selectedItem(item) {
    this.cargadorParametros = item;
  }

  agregar() {
    this.retornoObjeto.emit(this.cargadorParametros);
    this.ngSelectComponent.handleClearClick();
  }
}
