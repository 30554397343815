<ng-select [items]="contactos" bindLabel="concatNombreTelefono" [loading]="cargando" placeholder="Ingrese contacto ..."
  (selectItem)="selectedItem($event.item)" [(ngModel)]="cargadorContactos" (search)="onSearch($event)"
  class="ng-select">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <b [ngOptionHighlight]="search">{{item.concatNombreTelefono}}</b>
  </ng-template>
</ng-select>
<button class="btn btn-outline-secondary " type="button" (click)="agregar()">
  <i class="fa fa-plus-square"></i>
  <span>
    Agregar
  </span>
</button>