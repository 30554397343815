import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BASE_URL } from 'src/app/config/config';
import { Empresa } from 'src/app/models/empresa.model';
import { TotalesEmpresa } from 'src/app/models/totalesEmpresa.model';
import Swal from 'sweetalert2';



@Injectable()
export class EmpresaService {
  private urlEndPoint: string = BASE_URL;
  empresa: Empresa;

  headers: HttpHeaders; // new HttpHeaders();
  headersImagen: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + this.authService.token
    });
  }

  getById(id: number) {
    let url = BASE_URL + 'empresas/' + id;
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }
  getEmpresas() {
    let url = BASE_URL + 'empresas';
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }
  getImage(nombre: string) {
    let url = BASE_URL + 'empresas/download-image/' + nombre;
    return this.http.get(url, { headers: this.headers, responseType: 'blob' });
    // .map( data => data);
  }
  update(empresa: Empresa): Observable<Empresa> {
    return this.http.put<any>(BASE_URL + 'empresas', empresa, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getEmpresa(id): Observable<Empresa> {
    return this.http.get<Empresa>(`${BASE_URL}empresas/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        this.router.navigate(['/empresas']);
        console.error(e.error.mensaje);
        Swal.fire('Error al editar', e.error.mensaje, 'error');
        return throwError(e);
      })
    );
  }
  getTotalesEmpresa(id: string) {
    let url = BASE_URL + 'empresas/totales?idEmpresa=' + id;
    return this.http.get(url, { headers: this.headers })
      .map((resp: TotalesEmpresa) => resp);
  }

  getEstadosInstancia(id: string, page: number, size: number){
    let url = BASE_URL + 'empresas/estadosInstancia?idEmpresa=' + id + '&page=' + page + '&size=' + size;
    return this.http.get(url, { headers: this.headers })
      .map((resp: TotalesEmpresa) => resp);
  }

}
