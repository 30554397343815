import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent implements OnInit {
  userName;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.userName = this.authService.user.sub;
  }

}
